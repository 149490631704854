import { useState, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import NProgress from 'nprogress';
import Marquee from 'react-fast-marquee';
// material
import {
  experimentalStyled as styled,
  useTheme,
} from '@material-ui/core/styles';
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
  GlobalStyles,
} from '@material-ui/core';
// hooks
import useShopify from '../hooks/useShopify';
//
import Page from '../components/Page';
import LogoSmall from '../components/LogoSmall';
import NavigationContainer from './NavigationBar';
import { CODES_ARCHIVE_2023Q4 } from '../access-codes/archive-2023Q4';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  // overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  // overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
  },
}));

// ----------------------------------------------------------------------

export function ProgressBarStyle() {
  const theme = useTheme();

  return (
    <GlobalStyles
      styles={{
        '#nprogress': {
          pointerEvents: 'none',
          '& .bar': {
            top: 0,
            left: 0,
            height: 1,
            width: '100%',
            position: 'fixed',
            zIndex: theme.zIndex.snackbar,
            backgroundColor: theme.palette.primary.main,
          },
          '& .peg': {
            right: 0,
            opacity: 1,
            width: 100,
            height: '100%',
            display: 'block',
            position: 'absolute',
            transform: 'rotate(3deg) translate(0px, -4px)',
          },
        },
      }}
    />
  );
}

function ProgressBar() {
  NProgress.configure({
    showSpinner: false,
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return null;
}

const IS_CODE_REQUIED = false;

const checkCode = (code) => {
  /**
   * We trim the entered code, convert it to uppercase and remove all spaces and dashes.
   */
  return CODES_ARCHIVE_2023Q4.map((code) => code.replace(/-/g, '')).includes(
    code.trim().toUpperCase().replace(/\s/g, '').replace(/-/g, ''),
  );
};

export default function Layout({ isIntroduction, disableMarquee }) {
  const { settings } = useShopify();
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [isCodeChecked, setIsCodeChecked] = useState(false);

  const marqueeTexts = [];
  for (var i = 0; i < 100; i++) marqueeTexts.push(settings.notice);

  if (IS_CODE_REQUIED && !isCodeValid) {
    return (
      <Page title="RAUSCH" disablePadding>
        <Container
          maxWidth="md"
          sx={{
            minHeight: '100dvh',
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              py: 2,
            }}
          >
            <Typography variant="h1" align="center" sx={{ mb: 3 }}>
              Enter your code down below:
            </Typography>

            <Container maxWidth="sm">
              <Grid container rowGap={2}>
                <Grid item xs={12} md={8}>
                  <TextField
                    autoFocus
                    fullWidth
                    variant="outlined"
                    // name="CODE"
                    type="text"
                    placeholder="CODE"
                    value={code}
                    onChange={(e) => {
                      setCode(
                        e.target.value.trim().toUpperCase().replace(/\s/g, ''),
                      );
                      setIsCodeChecked(false);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    // name="subscribe"
                    type="submit"
                    disabled={!code}
                    onClick={() => {
                      setIsCodeChecked(true);
                      setIsCodeValid(checkCode(code));
                    }}
                    sx={{
                      height: '100%',
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Typography
                    variant="body1"
                    color="error"
                    align="center"
                    sx={{
                      visibility:
                        isCodeChecked && !isCodeValid ? 'visible' : 'hidden',
                    }}
                  >
                    The code you entered is invalid
                  </Typography>
                </Grid>
              </Grid>
            </Container>
          </Stack>

          <Stack
            alignItems="center"
            justifyContent="flex-end"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              py: 2,
              zIndex: -1,
            }}
          >
            <Typography variant="subtitle1" align="center">
              Archive Sale - 16.11.23 - 18:00
            </Typography>
            <LogoSmall
              sx={{
                width: 50,
              }}
            />
          </Stack>
        </Container>
      </Page>
    );
  }

  return (
    <RootStyle>
      <ProgressBar />
      <NavigationContainer
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      >
        <Outlet />
      </NavigationContainer>
      {/* <MainStyle> */}
      {/* </MainStyle> */}
      {!disableMarquee && (
        <Box sx={{ position: 'fixed', bottom: 20, left: 0, right: 0 }}>
          <Marquee gradient={false}>
            {marqueeTexts.map((text, index) => (
              <Typography key={index} variant="subtitle1" mr={0.6}>
                {text}
              </Typography>
            ))}
          </Marquee>
        </Box>
      )}
    </RootStyle>
  );
}
