import React, { useState, useEffect } from 'react';

const initialState = {
  checkout: {
    lineItems: [],
  },
  isLoading: false,
};

const CheckoutContext = React.createContext({
  ...initialState,
  addToCart: () => Promise.resolve(),
  increaseLineItemQuantity: () => Promise.resolve(),
  decreaseLineItemQuantity: () => Promise.resolve(),
});

export const CheckoutProvider = ({ client, children }) => {
  const [checkout, setCheckout] = useState(initialState.checkout);
  const [isLoading, setIsLoading] = useState(initialState.isLoading);

  useEffect(() => {
    const create = async () => {
      client.checkout.create().then((checkout) => {
        // console.log("CheckoutProvider create", checkout)
        setCheckout(checkout);
      });
    };

    create();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addToCart = async (variantId) => {
    setIsLoading(true);

    const lineItem = checkout.lineItems?.find(
      (lineItem) => lineItem.variant.id === variantId,
    );

    if (lineItem) {
      const quantity = (lineItem.quantity || 0) + 1;
      const lineItemsToUpdate = [{ id: lineItem.id, quantity: quantity }];

      client.checkout
        .updateLineItems(checkout.id, lineItemsToUpdate)
        .then((checkout) => {
          // console.log("CheckoutProvider addToCart", checkout)
          setCheckout(checkout);
          setIsLoading(false);
        });
    } else {
      const lineItemsToAdd = [{ variantId, quantity: 1 }];

      client.checkout
        .addLineItems(checkout.id, lineItemsToAdd)
        .then((checkout) => {
          // console.log("CheckoutProvider addToCart", checkout)
          setCheckout(checkout);
          setIsLoading(false);
        });
    }
  };

  const increaseLineItemQuantity = async (lineItemId) => {
    setIsLoading(true);

    const lineItem = checkout.lineItems?.find(
      (lineItem) => lineItem.id === lineItemId,
    );

    if (lineItem) {
      const quantity = Math.max((lineItem.quantity || 0) + 1, 0);
      const lineItemsToUpdate = [{ id: lineItemId, quantity: quantity }];

      client.checkout
        .updateLineItems(checkout.id, lineItemsToUpdate)
        .then((checkout) => {
          // console.log("CheckoutProvider increaseLineItemQuantity", checkout)
          setCheckout(checkout);
          setIsLoading(false);
        });
    }
  };

  const decreaseLineItemQuantity = async (lineItemId) => {
    setIsLoading(true);

    const lineItem = checkout.lineItems?.find(
      (lineItem) => lineItem.id === lineItemId,
    );

    if (lineItem) {
      const quantity = Math.max((lineItem.quantity || 0) - 1, 0);
      const lineItemsToUpdate = [{ id: lineItemId, quantity: quantity }];

      client.checkout
        .updateLineItems(checkout.id, lineItemsToUpdate)
        .then((checkout) => {
          // console.log("CheckoutProvider decreaseLineItemQuantity", checkout)
          setCheckout(checkout);
          setIsLoading(false);
        });
    }
  };

  return (
    <CheckoutContext.Provider
      value={{
        checkout,
        addToCart,
        isLoading,
        increaseLineItemQuantity,
        decreaseLineItemQuantity,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

export default CheckoutContext;
