import { useParams } from 'react-router-dom';
// material
import { Container } from '@material-ui/core';
// hooks
import useShopify from '../hooks/useShopify';
import useCheckout from '../hooks/useCheckout';
import useThemeMode from '../hooks/useThemeMode';
//
import Page from '../components/Page';
import ProductDisplay from '../layouts/ProductDisplay';

// ----------------------------------------------------------------------

export default function Product() {
  const { products } = useShopify();
  const { addToCart, isLoading } = useCheckout();
  const { changeThemeMode } = useThemeMode();

  changeThemeMode('light');

  const { productId } = useParams();
  const product = products?.find(
    (product) =>
      decodeURIComponent(product.id) === `gid://shopify/Product/${productId}`,
  );

  return (
    <Page
      disablePadding
      title={product ? `${product.title.toUpperCase()} * RAUSCH` : 'RAUSCH'}
      meta={
        <>
          <meta property="og:type" content="product" />
          <meta property="og:title" content={product?.title} />
          <meta property="product:plural_title" content={product?.title} />
          <meta
            property="og:url"
            content={`https://rausch.studio/products/${encodeURIComponent(
              product?.id.split('/').pop(),
            )}`}
          />
          <meta property="og:image" content={product?.images?.[0]?.src} />
          <meta property="og:description" content={product?.description} />
        </>
      }
    >
      <Container maxWidth="lg">
        {product && (
          <ProductDisplay
            product={product}
            onAddToCart={addToCart}
            isLoading={isLoading}
          />
        )}
      </Container>
    </Page>
  );
}
