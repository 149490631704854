import { useRef, useEffect } from 'react';
// hooks
import useThemeMode from '../hooks/useThemeMode';
//
import Page from '../components/Page';

// ----------------------------------------------------------------------

export default function MaintenanceNewLogo2023() {
  const videoRef = useRef();
  const { changeThemeMode } = useThemeMode();

  changeThemeMode('dark');

  /**
   * React ignores the "muted" attribute on the video tag.
   * @source https://github.com/facebook/react/issues/10389#issuecomment-1602142645
   */
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  });

  return (
    <Page title="RAUSCH" disablePadding>
      <video
        ref={videoRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          objectPosition: 'center',
        }}
        loop
        muted
        autoPlay
        playsInline
      >
        <source src={`/static/NEW_LOGO_2023.mp4`} type="video/mp4" />
      </video>

      {/* <Container
        maxWidth="md"
        sx={{
          height: "100dvh",
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            py: 2,
            zIndex: -1,
          }}
        >
          <LogoSmall
            sx={{
              width: 50,
            }}
          />
        </Stack>
      </Container> */}
    </Page>
  );
}
