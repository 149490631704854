import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// material
import { Box } from '@material-ui/core';
// utils
// import track from '../utils/analytics';

// ----------------------------------------------------------------------

const Page = forwardRef(
  ({ children, title = '', disablePadding, meta, ...other }, ref) => {
    // const { pathname } = useLocation();

    // const sendPageViewEvent = useCallback(() => {
    // 	track.pageview({
    // 		page_path: pathname
    // 	});
    // 	// eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    // useEffect(() => {
    // 	sendPageViewEvent();
    // }, [sendPageViewEvent]);

    return (
      <Box sx={{ ...(!disablePadding && { py: 10 }) }}>
        <Helmet>
          <title>{title}</title>
          {meta}
        </Helmet>
        {children}
      </Box>
    );
  },
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  disablePadding: PropTypes.bool,
  meta: PropTypes.node,
};

export default Page;
