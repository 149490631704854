// material
import { Container, Typography, Stack } from '@material-ui/core';
// hooks
import useShopify from '../hooks/useShopify';
import useThemeMode from '../hooks/useThemeMode';
//
import Page from '../components/Page';
import ProductGrid from '../layouts/ProductGrid';

// ----------------------------------------------------------------------

export default function Products() {
  const { products } = useShopify();
  const { changeThemeMode } = useThemeMode();

  changeThemeMode('light');

  return (
    <Page title="PRODUCTS * RAUSCH">
      <Container maxWidth="lg">
        {products && products?.length > 0 && (
          <ProductGrid
            products={products}
            sx={{ marginTop: (theme) => theme.spacing(0) }}
          />
        )}
        {products && !products?.length > 0 && (
          <Stack alignItems="center" justifyContent="center" sx={{ my: 32 }}>
            <Typography variant="overline">No products available</Typography>
          </Stack>
        )}
        {!products && (
          <Stack alignItems="center" justifyContent="center" sx={{ my: 32 }}>
            <Typography variant="overline">Loading...</Typography>
          </Stack>
        )}
      </Container>
    </Page>
  );
}
