import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: 'https://rausch-studio-backend.herokuapp.com/api/',
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) ||
        'Something went wrong, please check your internet connection',
    ),
);

export default axiosInstance;
