import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
// material
import { Grid } from '@material-ui/core';
//
import Product from '../components/Product';

// ----------------------------------------------------------------------

ProductGrid.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProductGrid({ products, ...other }) {
  const navigate = useNavigate();

  return (
    <Grid container spacing={6} {...other}>
      {products?.map((product) => (
        <Grid
          key={product.id}
          item
          xs={6}
          md={4}
          lg={3}
          sx={{
            cursor: 'pointer',
            // cursor: 'progress'
          }}
          onClick={() =>
            navigate(
              `/products/${encodeURIComponent(product.id.split('/').pop())}`,
            )
          }
        >
          <Product product={product} />
        </Grid>
      ))}
    </Grid>
  );
}
