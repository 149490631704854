import PropTypes from 'prop-types';
// material
import { Stack, useTheme } from '@material-ui/core';
//
import LazySize from './LazySize';

// ----------------------------------------------------------------------

ImageGallery.propTypes = {
  images: PropTypes.array.isRequired,
};

export default function ImageGallery({ images }) {
  const theme = useTheme();

  return (
    <Stack
      direction="column"
      justifyContent="center"
      spacing={5}
      sx={{
        padding: (theme) => ({
          xs: theme.spacing(6, 0),
          sm: theme.spacing(10, 0),
          md: theme.spacing(0, 10),
          lg: theme.spacing(0, 12),
        }),
      }}
    >
      {images.map((image) => (
        <LazySize
          key={image.id}
          alt="Product Image"
          src={image.src}
          sx={{
            objectFit: 'contain',
            height: '50vh',
            [theme.breakpoints.up('md')]: {
              height: '100vh',
            },
          }}
        />
      ))}
    </Stack>
  );
}
