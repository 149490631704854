import { useParams } from 'react-router-dom';
// material
import { Container, Box } from '@material-ui/core';
// hooks
import useThemeMode from '../hooks/useThemeMode';
//
import {
  contact,
  shipping,
  imprint,
  returns,
  privacy,
  terms,
} from '../components/Texts';
import Page from '../components/Page';
import Markdown from '../components/Markdown';

// ----------------------------------------------------------------------

export default function Info() {
  const { changeThemeMode } = useThemeMode();

  let content = '';

  const { contentTag } = useParams();

  // eslint-disable-next-line default-case
  switch (contentTag) {
    case 'contact':
      content = contact;
      break;
    case 'shipping':
      content = shipping;
      break;
    case 'imprint':
      content = imprint;
      break;
    case 'returns':
      content = returns;
      break;
    case 'privacy':
      content = privacy;
      break;
    case 'terms':
      content = terms;
      break;
  }

  changeThemeMode('dark');

  return (
    <Page title="INFO * RAUSCH">
      <Container maxWidth="md">
        <Box
          sx={{
            marginTop: {
              xs: 5,
              sm: 10,
              lg: 20,
            },
            marginBottom: {
              xs: 5,
              sm: 10,
              lg: 20,
            },
          }}
        >
          <Markdown children={content || ''} />
        </Box>
      </Container>
    </Page>
  );
}
