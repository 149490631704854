import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

LogoSmall.propTypes = {
  sx: PropTypes.object,
};

export default function LogoSmall({ sx }) {
  //   const theme = useTheme();

  return (
    <Box
      sx={{
        width: 340,
        height: 34.5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Ebene 1"
        viewBox="0 0 677.59 110.53"
        style={{
          height: '100%',
        }}
      >
        <path
          fill={sx?.color}
          d="M45.38 74.68H32.59v33.48H0V2.37h56.15c25.78 0 41.34 13.04 41.34 36.15 0 15.35-6.87 26.26-19.03 31.86l22.59 37.78H63.27L45.39 74.68zm4.99-24.45c9.33 0 13.93-4.59 13.93-11.7s-4.59-11.7-13.93-11.7H32.59v23.41h17.78zM144.8 88.31l-5.84 19.86h-34.08l36.15-105.8h44.45l36.15 105.79h-34.67l-5.99-19.86h-36.18zm18.16-59.71h-.59l-11.03 37.49h22.93L162.96 28.6zM321.26 69.19c0 28.6-21.34 41.34-49.49 41.34s-49.49-12.74-49.49-41.34V2.37h32.6v63.86c0 12.3 5.78 18.82 16.89 18.82s16.89-6.52 16.89-18.82V2.37h32.6v66.82zM391.85 35.12c-.59-7.26-5.33-12.15-15.56-12.15-7.7 0-12.74 3.41-12.74 7.85s4.59 6.08 11.11 7.71l16.15 4.15c16.74 4.29 35.71 8.89 35.71 34.52 0 24-22.08 33.34-47.56 33.34s-48.3-12-50.08-35.41l32.15-3.26c.59 10.22 8.15 15.12 18.22 15.12 7.71 0 14.08-2.81 14.08-8.45s-4.3-7.26-14.23-9.92l-13.63-3.7c-15.85-4.3-33.64-8.89-33.64-31.71S351.85 0 377.77 0c23.85 0 42.22 11.26 45.04 33.19l-30.97 1.92zM537.36 68.75c-3.85 24.3-23.56 41.78-52.16 41.78-31.56 0-54.23-19.56-54.23-55.26S453.65 0 485.21 0c28 0 47.12 17.19 51.56 37.34l-32.75 3.41c-2.37-8.74-9.04-15.26-18.82-15.26s-21.04 6.07-21.04 29.78 11.56 29.78 21.04 29.78 17.04-6.52 19.11-18.82l33.04 2.52zM645.05 108.16h-32.59V67.71h-34.67v40.45h-32.6V2.37h32.6v39.12h34.67V2.37h32.59v105.79zM677.59 13.27c0 6.08-4.96 11.04-11.07 11.04s-11.04-4.96-11.04-11.04S660.44 2.2 666.52 2.2s11.07 4.96 11.07 11.07zm-2.37 0c0-4.78-3.92-8.7-8.7-8.7s-8.67 3.92-8.67 8.7 3.89 8.67 8.67 8.67 8.7-3.89 8.7-8.67zm-5.64 1.51l2.29 3.77h-3.8l-1.78-3.35h-1.28v3.35h-3.29V7.92h5.64c2.61 0 4.16 1.31 4.16 3.62 0 1.57-.68 2.67-1.93 3.24zm-2.85-2.05c.92 0 1.4-.48 1.4-1.19s-.47-1.16-1.4-1.16h-1.72v2.35h1.72z"
        ></path>
      </svg>
    </Box>
  );
}
