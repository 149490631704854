import React, { useState, useEffect } from 'react';
// utils
import axios from '../utils/axios';

const initialState = {
  client: {},
  products: null,
  collections: [],
  shop: {},
  settings: {
    notice: '',
    comingSoon: false,
    comingSoonText: 'Coming soon',
  },
  posts: [],
  archives: [],
  aboutPageContent: '',
  infoPageLinks: [],
  hero: {
    content: {
      type: 'image',
      source: '',
    },
    primaryColor: '#ffffff',
    backgroundColor: '#000000',
  },
};

const ShopifyContext = React.createContext({
  ...initialState,
});

export const ShopifyProvider = ({ client, children }) => {
  const [products, setProducts] = useState(initialState.products);
  const [collections, setCollections] = useState(initialState.collections);
  const [shop, setShop] = useState(initialState.shop);
  const [settings, setSettings] = useState(initialState.settings);
  const [posts, setPosts] = useState(initialState.posts);
  const [archives, setArchives] = useState(initialState.archives);
  const [aboutPageContent, setAboutPageContent] = useState(
    initialState.aboutPageContent,
  );
  const [infoPageLinks, setInfoPageLinks] = useState(
    initialState.infoPageLinks,
  );
  const [hero, setHero] = useState(initialState.hero);

  const fetchMetafieldProducts = async () => {
    try {
      /**
       * The previous code (https://github.com/Shopify/js-buy-sdk/issues/697#issuecomment-527946750)
       * stopped working on 2023-07-06.
       *
       * Apparently Shopify shut down any API version older than 2022-10.
       * The new code is based on the following comment:
       * @source https://github.com/Shopify/js-buy-sdk/issues/960#issuecomment-1624502160
       */
      const metafieldQuery = client.graphQLClient.query((root) => {
        // FIXME: Magic number!
        root.addConnection('products', { args: { first: 249 } }, (product) => {
          product.add('handle');
          product.add(
            'metafields',
            {
              args: {
                identifiers: [
                  { namespace: 'settings', key: 'original_price' },
                  { namespace: 'settings', key: 'sort_order' },
                  { namespace: 'settings', key: 'coming_soon' },
                  { namespace: 'sizing', key: 'model-sizing' },
                  { namespace: 'sizing', key: 'model-heights' },
                  { namespace: 'sizing', key: 'model-names' },
                ],
              },
            },
            (metafield) => {
              metafield.add('namespace');
              metafield.add('key');
              metafield.add('value');
            },
          );
        });
      });

      const graphProductMetafields =
        await client.graphQLClient.send(metafieldQuery);

      const metafieldProducts = {};

      graphProductMetafields?.data?.products?.edges?.forEach((graphProduct) => {
        const { node } = graphProduct;
        const { id, metafields: graphMetafields } = node;

        try {
          const metafields = {};

          graphMetafields.forEach((graphMetafield) => {
            if (!graphMetafield) return;

            const { namespace, key, value } = graphMetafield;

            const { [namespace]: previousMetafieldsInNamespace } = metafields;
            metafields[namespace] = {
              ...previousMetafieldsInNamespace,
              [key]: JSON.parse(value),
            };
          });

          return (metafieldProducts[id] = metafields);
        } catch (error) {
          console.error(error);

          return (metafieldProducts[id] = {});
        }
      });

      // console.log("ShopifyProvider fetchMetafieldProducts", metafieldProducts)
      return metafieldProducts;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchProductsAndCollections = async () => {
      try {
        const graphProducts = await client.product.fetchAll(100);
        const graphCollections = await client.collection.fetchAllWithProducts({
          productsFirst: 100,
        });
        const metafieldProducts = await fetchMetafieldProducts();

        const products = graphProducts.map((graphProduct) => {
          return {
            ...graphProduct,
            metafields: metafieldProducts?.[graphProduct.id] ?? {},
          };
        });

        const collections = graphCollections.map(
          ({ products: graphCollectionProducts, ...graphCollection }) => {
            return {
              ...graphCollection,
              // TODO: Does this work?
              products: graphCollectionProducts
                .map((graphProduct) => {
                  return {
                    ...graphProduct,
                    metafields: metafieldProducts?.[graphProduct.id] ?? {},
                  };
                })
                .sort((a, b) => {
                  /**
                   * The product with the highest sort_order should be first.
                   * If a product doesn't have a sort_order, it should be last.
                   * If two products have the same sort_order, they should be sorted alphabetically.
                   */
                  const aSortOrder = a.metafields?.settings?.['sort_order'];
                  const bSortOrder = b.metafields?.settings?.['sort_order'];

                  if (aSortOrder && bSortOrder) {
                    if (aSortOrder > bSortOrder) return -1;
                    if (aSortOrder < bSortOrder) return 1;
                  }

                  if (aSortOrder && !bSortOrder) return -1;
                  if (!aSortOrder && bSortOrder) return 1;

                  return a.title.localeCompare(b.title);
                }),
            };
          },
        );

        // console.log("ShopifyProvider fetchProductsAndCollections", products, collections)
        /**
         * The product with the highest sort_order should be first.
         * If a product doesn't have a sort_order, it should be last.
         * If two products have the same sort_order, they should be sorted alphabetically.
         */
        const sortedProducts = products.sort((a, b) => {
          const aSortOrder = a.metafields?.settings?.['sort_order'];
          const bSortOrder = b.metafields?.settings?.['sort_order'];

          if (aSortOrder && bSortOrder) {
            if (aSortOrder > bSortOrder) return -1;
            if (aSortOrder < bSortOrder) return 1;
          }

          if (aSortOrder && !bSortOrder) return -1;
          if (!aSortOrder && bSortOrder) return 1;

          return a.title.localeCompare(b.title);
        });
        setProducts(sortedProducts);
        setCollections(collections);
      } catch (error) {
        // console.log(error)
      }
    };

    const fetchShop = async () => {
      try {
        client.shop.fetchInfo().then((shop) => {
          // console.log("ShopifyProvider fetchShop", shop)
          setShop(shop);
        });
      } catch (error) {
        // console.log(error)
      }
    };

    const fetchSettings = async () => {
      try {
        const response = await axios.get('/setting');
        const settings =
          response.data?.data?.attributes || initialState.settings;
        // console.log("ShopifyProvider fetchSettings", response, settings);
        setSettings(settings);
      } catch (error) {
        // console.log(error)
      }
    };

    const fetchPosts = async () => {
      try {
        // const response = await axios.get('/posts?filters[archive]&populate=*');
        const response = await axios.get('/posts?populate=*');
        const posts = response.data?.data || [];
        // console.log("ShopifyProvider fetchPosts", response, posts);
        setPosts(posts);
      } catch (error) {
        // console.log(error)
      }
    };

    const fetchArchives = async () => {
      try {
        const response = await axios.get('/archives?populate=*');
        const archives = response.data?.data || [];
        // console.log("ShopifyProvider fetchArchives", response, archives);
        setArchives(
          archives
            .sort((a, b) =>
              a.attributes?.title?.localeCompare(b.attributes?.title),
            )
            .map((archiveCategory) => {
              return {
                ...archiveCategory,
                attributes: {
                  ...archiveCategory.attributes,
                  posts: {
                    ...archiveCategory.attributes?.posts,
                    data: archiveCategory.attributes?.posts?.data?.sort(
                      (a, b) =>
                        a.attributes?.title?.localeCompare(b.attributes?.title),
                    ),
                  },
                },
              };
            }),
        );
      } catch (error) {
        // console.log(error)
      }
    };

    const fetchAboutPageContent = async () => {
      try {
        const response = await axios.get('/about-page?populate=*');
        const content = response.data?.data.attributes?.content || '';
        // console.log("ShopifyProvider fetchAboutPageContent", response, content);
        setAboutPageContent(content);
      } catch (error) {
        // console.log(error)
      }
    };

    const fetchInfoPageLinks = async () => {
      try {
        const response = await axios.get('/info-page?populate=*');
        const links = response.data?.data.attributes?.links || [];
        // console.log("ShopifyProvider fetchInfoPageLinks", response, content);
        setInfoPageLinks(links);
      } catch (error) {
        // console.log(error)
      }
    };

    const fetchHero = async () => {
      try {
        const response = await axios.get('/hero-section?populate=*');
        // const source = `https://rausch-studio-backend.herokuapp.com${response.data?.data.attributes?.video?.data?.attributes?.url}` || "";
        const contentSource =
          response.data?.data.attributes?.content?.data?.attributes?.url || '';
        const contentMime =
          response.data?.data.attributes?.content?.data?.attributes?.mime ||
          'image/png';
        const primaryColor =
          response.data?.data.attributes?.primary_color_hex || '#ffffff';
        const backgroundColor =
          response.data?.data.attributes?.background_color_hex || '#000000';
        const heroData = {
          content: {
            type: contentMime.includes('video') ? 'video' : 'image',
            source: contentSource,
          },
          primaryColor,
          backgroundColor,
        };
        // console.log("ShopifyProvider fetchHero", response, heroData);
        setHero(heroData);
      } catch (error) {
        // console.log(error)
      }
    };

    fetchProductsAndCollections();
    fetchShop();
    fetchSettings();
    fetchPosts();
    fetchArchives();
    fetchAboutPageContent();
    fetchInfoPageLinks();
    fetchHero();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ShopifyContext.Provider
      value={{
        client,
        products,
        collections,
        shop,
        settings,
        posts,
        archives,
        aboutPageContent,
        infoPageLinks,
        hero,
      }}
    >
      {children}
    </ShopifyContext.Provider>
  );
};

export default ShopifyContext;
