import React, { useState } from 'react';

const initialState = 'light';

const ThemeModeContext = React.createContext({
  ...initialState,
  changeThemeMode: () => Promise.resolve(),
});

export const ThemeModeProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useState(initialState);

  const changeThemeMode = (themeMode = 'light') => {
    setThemeMode(themeMode);
  };

  return (
    <ThemeModeContext.Provider
      value={{
        themeMode,
        changeThemeMode,
      }}
    >
      {children}
    </ThemeModeContext.Provider>
  );
};

export default ThemeModeContext;
