// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
//
// import Logo from './Logo';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.introduction.background,
}));

// ----------------------------------------------------------------------

export default function LoadingScreen({ ...other }) {
  return <RootStyle {...other}>{/* <Logo /> */}</RootStyle>;
}
