import { Link as RouterLink } from 'react-router-dom';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Stack,
  TableFooter,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
// hooks
import useCheckout from '../hooks/useCheckout';
import useThemeMode from '../hooks/useThemeMode';
//
import Page from '../components/Page';
import LazySize from '../components/LazySize';
import Delete from '../components/Delete';
import MHidden from '../components/@material-extend/MHidden';

// ----------------------------------------------------------------------

export default function Cart() {
  const theme = useTheme();
  const {
    checkout,
    isLoading,
    increaseLineItemQuantity,
    decreaseLineItemQuantity,
  } = useCheckout();
  const { changeThemeMode } = useThemeMode();

  changeThemeMode('light');

  const handleOpenCheckout = () => {
    window.open(checkout.webUrl);
  };

  return (
    <Page title={'CART * RAUSCH'}>
      <Container maxWidth="lg">
        <Grid
          container
          sx={{
            marginTop: {
              xs: 5,
              sm: 10,
              lg: 20,
            },
            marginBottom: {
              xs: 5,
              sm: 10,
              lg: 20,
            },
          }}
        >
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            sx={{
              mb: 2,
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h1">{`Products(${checkout?.lineItems.reduce((partial_sum, lineItem) => partial_sum + lineItem.quantity, 0) || 0})`}</Typography>
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow>
                <MHidden width="mdDown">
                  <TableCell
                    sx={{
                      [theme.breakpoints.down('md')]: {
                        display: 'none',
                      },
                    }}
                  />
                </MHidden>
                <TableCell>Product</TableCell>
                <TableCell>Variant</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell sx={{ textAlign: 'right' }}>Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {checkout.lineItems.map((lineItem) => {
                const imageSource = lineItem.variant?.image?.src;

                return (
                  <TableRow key={lineItem.id}>
                    <MHidden width="mdDown">
                      <TableCell
                        sx={{
                          width: 150,
                        }}
                      >
                        {imageSource && (
                          <LazySize alt="Product Image" src={imageSource} />
                        )}
                      </TableCell>
                    </MHidden>
                    <TableCell>
                      <Button
                        component={RouterLink}
                        to={`/products/${encodeURIComponent(lineItem.variant.product.id.split('/').pop())}`}
                        variant="text"
                      >
                        {lineItem.title}
                      </Button>
                    </TableCell>
                    <TableCell>
                      {lineItem.variant.title !== 'Default Title' &&
                        lineItem.variant.title}
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" alignItems="center">
                        {lineItem.quantity}
                        <Stack direction="column" ml={2} spacing={0.25}>
                          <Button
                            variant="outlined"
                            onClick={() =>
                              increaseLineItemQuantity(lineItem.id)
                            }
                          >
                            +
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() =>
                              decreaseLineItemQuantity(lineItem.id)
                            }
                          >
                            -
                          </Button>
                        </Stack>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'right' }}>
                      {`${(Number(lineItem.quantity) * Number(lineItem.variant.priceV2.amount)).toFixed(0)}€`}
                    </TableCell>
                    {/* <TableCell>
                      <Delete onClick={() => decreaseLineItemQuantity(lineItem.id)} />
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <MHidden width="mdDown">
                  <TableCell
                    sx={{
                      borderRight: 'none',
                    }}
                  />
                </MHidden>
                <TableCell colSpan={4}>
                  <Typography
                    variant="subtitle2"
                    sx={{ textAlign: 'right' }}
                  >{`Total: ${Number(checkout.totalPriceV2.amount).toFixed(0)}€`}</Typography>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            sx={{
              width: '100%',
              mt: 2,
            }}
          >
            {checkout.lineItems?.length > 0 && (
              <LoadingButton
                loading={isLoading}
                variant="contained"
                onClick={handleOpenCheckout}
                sx={{ padding: theme.spacing(0.5, 3) }}
              >
                <Typography variant="subtitle1">Checkout</Typography>
              </LoadingButton>
            )}
          </Stack>
        </Grid>
      </Container>
    </Page>
  );
}
