import { m, AnimatePresence } from 'framer-motion';
// @mui
import { Box, Button, Container, Portal, Stack } from '@material-ui/core';
//
import { varZoom } from '../components/animate/variants';
import { useEffect, useMemo } from 'react';

// ----------------------------------------------------------------------

export default function Modal({ children, isOpen, onClose }) {
  const wasSetInitially = useMemo(() => {
    return document.body.classList.contains('noscroll');
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('noscroll');
    } else {
      if (!wasSetInitially) {
        document.body.classList.remove('noscroll');
      }
    }

    return () => {
      if (!wasSetInitially) {
        document.body.classList.remove('noscroll');
      }
    };
  }, [isOpen, wasSetInitially]);

  return (
    <Portal>
      <AnimatePresence>
        {isOpen && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              bgcolor: 'background.paper',
              px: 2,
              py: {
                xs: 7,
                md: 20,
              },
              zIndex: (theme) => theme.zIndex.modal,
            }}
            component={m.div}
            {...varZoom({
              distance: 50,
              scale: 0.9,
              durationIn: 0.3,
              durationOut: 0.3,
            }).inUp}
          >
            <Container maxWidth="md" sx={{ height: '100%' }}>
              <Stack direction="column" sx={{ height: '100%' }}>
                {children}
                <Button variant="outlined" onClick={onClose}>
                  Close
                </Button>
              </Stack>
            </Container>
          </Box>
        )}
      </AnimatePresence>
    </Portal>
  );
}
