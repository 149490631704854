// material
import { Container, Box } from '@material-ui/core';
// hooks
import useShopify from '../hooks/useShopify';
import useThemeMode from '../hooks/useThemeMode';
//
import Page from '../components/Page';
import Markdown from '../components/Markdown';

// ----------------------------------------------------------------------

export default function About() {
  const { aboutPageContent } = useShopify();
  const { changeThemeMode } = useThemeMode();

  changeThemeMode('dark');

  return (
    <Page title="ABOUT * RAUSCH">
      <Container maxWidth="md">
        <Box
          sx={{
            marginTop: {
              xs: 5,
              sm: 10,
              lg: 20,
            },
            marginBottom: {
              xs: 5,
              sm: 10,
              lg: 20,
            },
          }}
        >
          <Markdown children={aboutPageContent || ''} />
        </Box>
      </Container>
    </Page>
  );
}
